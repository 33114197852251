import gql from "graphql-tag";

const READINGS = gql`
  query Readings($plantId: String!) {
    readings(plantId: $plantId) {
      id
      light
      moisture
      temperature
      humidity
      battery
      signal
      createdAt
    }
  }
`;

export default READINGS;
